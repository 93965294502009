<template>
	<v-row>
		<v-dialog v-model="dialog" persistent max-width="450px">
			<v-card>
				<v-card-title>Novo registro</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row class="mt-3">
						<v-col cols="12" class="mb-n7">
							<v-autocomplete
								:items="dados.lista"
								item-text="filial"
								item-value="chave"
								label="Filial"
								outlined
								dense
								v-model="novo.idempresa"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" class="mb-n7">
							<v-autocomplete
								:items="cargos.cargos"
								item-text="cargo"
								item-value="idcargo"
								label="Cargo"
								outlined
								dense
								v-model="novo.idcargo"
							></v-autocomplete>
						</v-col>
						<v-col cols="12" class="mb-n7">
							<v-text-field label="Funcionários fixos" type="number" outlined dense v-model="novo.qtd"></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
					<v-btn
						:disabled="carregando"
						:loading="carregando"
						color="primary"
						elevation="0"
						@click="definirFuncFixos(novo.idempresa.split('-')[0], novo.idempresa.split('-')[1], novo.idcargo, novo.qtd)"
					>Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					Total de colaboradores fixos e atuais por filial e cargo
					<v-spacer />
					<v-btn elevation="0" color="primary" fab small @click="novo = {}, dialog = true">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text v-if="carregandoSkeleton">
					<v-skeleton-loader type="image, image" width="100%" height="400" />
				</v-card-text>
				<v-expansion-panels v-else flat>
					<v-expansion-panel
						v-for="(filial, i) in dados.lista"
						:key="i"
						active-class="primary white--text rounded-0"
					>
						<v-divider />
						<v-expansion-panel-header class="py-2" v-slot="{ open }" hide-actions>
							<v-row no-gutters align="center">
								<v-col cols="4" class="text-h6 text-truncate">{{formataNome(filial.filial)}}</v-col>
								<v-col cols="8">
									<v-fade-transition hide-on-leave>
										<v-row v-if="!open" no-gutters style="width: 100%">
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Total de funcionários fixos
												<br />
												<v-chip color="primary" small class="mt-1 px-2 px-md-5">
													<template
														v-if="filial.funccargos"
													>{{totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionariosfixos }))}}</template>
													<template v-else>0</template>
												</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Total de funcionários atuais
												<br />
												<v-chip color="green darken-1" dark small class="mt-1 px-2 px-md-5">
													<template
														v-if="filial.funccargos"
													>{{totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionarios }))}}</template>
													<template v-else>0</template>
												</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Diferença
												<br />
												<v-chip color="red darken-3" dark small class="mt-1 px-2 px-md-5">
													<template v-if="filial.funccargos">
														{{
														totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionariosfixos })) - totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionarios }))
														}}
													</template>
													<template v-else>0</template>
												</v-chip>
											</v-col>
										</v-row>
									</v-fade-transition>
								</v-col>
							</v-row>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="rounded-0">
								<thead>
									<tr class="blue-grey lighten-4">
										<th>Cargo</th>
										<th class="text-center">Total de funcionários fixos</th>
										<th class="text-center">Total de funcionários atual</th>
										<th class="text-center">Diferença</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(cargo, i) in filial.funccargos" :key="i">
										<td>{{cargo.cargo}}</td>
										<td class="text-center">
											<v-text-field
												:disabled="carregando"
												class="mx-auto py-0 mb-n3"
												reverse
												style="width: 100px;"
												type="number"
												v-model="cargo.qtdfuncionariosfixos"
												dense
												@change="definirFuncFixos(filial.numEmprSenior, filial.idfilial, cargo.idcargo, cargo.qtdfuncionariosfixos)"
											></v-text-field>
										</td>
										<td class="text-center">{{cargo.qtdfuncionarios || 0}}</td>
										<td
											class="text-center"
										>{{(cargo.qtdfuncionariosfixos || 0) - (cargo.qtdfuncionarios || 0)}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr class="blue-grey lighten-4">
										<td class="font-weight-bold">Total por filial</td>
										<td class="text-center font-weight-bold">
											<template
												v-if="filial.funccargos"
											>{{totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionariosfixos }))}}</template>
											<template v-else>0</template>
										</td>
										<td class="text-center font-weight-bold">
											<template
												v-if="filial.funccargos"
											>{{totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionarios }))}}</template>
											<template v-else>0</template>
										</td>
										<td class="text-center font-weight-bold">
											<template v-if="filial.funccargos">
												{{
												totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionariosfixos })) - totalFuncionariosPorFilial(filial.funccargos.map((v) => { return v.qtdfuncionarios }))
												}}
											</template>
											<template v-else>0</template>
										</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel active-class="primary white--text rounded-0">
						<v-divider />
						<v-expansion-panel-header class="py-2" v-slot="{ open }" hide-actions>
							<v-row no-gutters align="center">
								<v-col cols="4" class="text-h6 text-truncate">Total por cargos</v-col>
								<v-col cols="8" class="text--secondary">
									<v-fade-transition hide-on-leave>
										<v-row v-if="!open" no-gutters style="width: 100%">
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Total de funcionários fixos
												<br />
												<v-chip color="primary" small class="mt-1 px-2 px-md-5">
													<template v-if="dados.total">{{totalFuncionariosFixosRede(dados.lista)}}</template>
													<template v-else>0</template>
												</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Total de funcionários atuais
												<br />
												<v-chip color="green darken-1" dark small class="mt-1 px-2 px-md-5">
													<template v-if="dados.total">{{totalFuncionariosAtuaisRede(dados.lista)}}</template>
													<template v-else>0</template>
												</v-chip>
											</v-col>
											<v-col class="text-center black--text font-weight-bold text-truncate">
												Diferença
												<br />
												<v-chip color="red darken-3" dark small class="mt-1 px-2 px-md-5">
													<template v-if="dados.total">
														{{
														totalFuncionariosFixosRede(dados.lista) - totalFuncionariosAtuaisRede(dados.lista)
														}}
													</template>
													<template v-else>0</template>
												</v-chip>
											</v-col>
										</v-row>
									</v-fade-transition>
								</v-col>
							</v-row>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="rounded-0">
								<thead>
									<tr class="blue-grey lighten-4">
										<th>Cargo</th>
										<th class="text-center">Total de funcionários fixos</th>
										<th class="text-center">Total de funcionários atual</th>
										<th class="text-center">Diferença</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(c, i) in dados.total" :key="i">
										<td>{{c.cargo}}</td>
										<td class="text-center">{{c.qtdfuncionariosfixos || 0}}</td>
										<td class="text-center">{{c.qtdfuncionarios || 0}}</td>
										<td class="text-center">{{(c.qtdfuncionariosfixos || 0) - (c.qtdfuncionarios || 0)}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr class="blue-grey lighten-4">
										<td class="font-weight-bold">Total geral</td>
										<td class="text-center font-weight-bold">
											<template v-if="dados.total">{{totalFuncionariosFixosRede(dados.lista)}}</template>
											<template v-else>0</template>
										</td>
										<td class="text-center font-weight-bold">
											<template v-if="dados.total">{{totalFuncionariosAtuaisRede(dados.lista)}}</template>
											<template v-else>0</template>
										</td>
										<td class="text-center font-weight-bold">
											<template v-if="dados.total">
												{{
												totalFuncionariosFixosRede(dados.lista) - totalFuncionariosAtuaisRede(dados.lista)
												}}
											</template>
											<template v-else>0</template>
										</td>
									</tr>
								</tfoot>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "DepartamentoPessoalQuadroFuncionarios",
	components: { CardDialog },
	data: () => ({
		novo: {},
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		carregando: false,
		carregandoSkeleton: true,
		dados: {},
		cargos: [],
		busca: {
			dtmes: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}`, // +1
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dp/quadro-funcionarios`)
				.then((res) => {
					res.data.lista.forEach((v) => {
						v.chave = `${v.numEmprSenior}-${v.idfilial}`;
					});
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		listarCargos() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dp/cargos`)
				.then((res) => {
					this.cargos = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		totalFuncionariosPorFilial(array) {
			if (array) {
				let soma = 0;
				array.forEach((v) => {
					v ? (soma += parseInt(v)) : (soma += 0);
				});
				return soma;
			}
		},
		totalFuncionariosFixosRede(array) {
			if (array) {
				let soma = 0;
				array.forEach((vf) => {
					if (vf.funccargos) {
						vf.funccargos.forEach((v) => {
							v.qtdfuncionariosfixos
								? (soma += parseInt(v.qtdfuncionariosfixos))
								: (soma += 0);
						});
					}
				});
				return soma;
			}
		},
		totalFuncionariosAtuaisRede(array) {
			if (array) {
				let soma = 0;
				array.forEach((vf) => {
					if (vf.funccargos) {
						vf.funccargos.forEach((v) => {
							v.qtdfuncionarios
								? (soma += parseInt(v.qtdfuncionarios))
								: (soma += 0);
						});
					}
				});
				return soma;
			}
		},
		definirFuncFixos(idempresa, idfilial, idcargo, qtd) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dp/cargo/cadastrar`, {
					numEmprSenior: parseInt(idempresa),
					idfilial: parseInt(idfilial),
					idcargo: parseInt(idcargo),
					qtdfuncionariosfixos: parseInt(qtd),
				})
				.then((res) => {
					this.carregando = false;
					this.dialog = false;
					if (res.data) {
						this.dialogErro.title = "Cadastro efetuado";
						this.dialogErro.msg =
							"Cadastro de funcionários fixos realizado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.listar();
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível alterar a quantidade de funcionários fixos!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialog = false;
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível alterar a quantidade de funcionários fixos!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		formataNome(nome) {
			if (nome == "CASTELO MATERIAIS DE CONSTRUÇÃO") {
				return "Castelo";
			} else if (nome == "Logística São Mateus do Sul") {
				return "Log. São Mateus do Sul";
			} else if (nome == "Apucarana - CD") {
				return "Apucarana CD";
			} else if (nome == "MARINGA - CD") {
				return "Maringá CD";
			} else {
				return nome;
			}
		},
		async init() {
			this.listar();
			this.listarCargos();
		},
	},
	created() {
		this.init();
	},
};
</script>